<template>
    <div  class="personindex">
        <div class="h1">黄骅市综合服务平台打造您的专属主页</div>
        <div class="h2">打造企业想用爱用常用的企业服务金名片</div>
        <div  class="top flex">
          <div class="denglu"></div>
          <div class="people flex-1">
             <div  class="title">个人信息</div>
            <div class="flex">
              <div class="touxiang">{{userInfo.name}}</div>
              <div class="flex-1 xinxi">
                  <div class="status">{{userInfo.role_name=='person_without_enterprise'?'暂未关联企业':'已关联企业'}}</div>
                  <div  class="name">{{userInfo.account}}
                    <span  class="btn" @click="goto(0)" v-if="userInfo.role_name=='person_without_enterprise'">去完善企业信息</span>
                    <span  class="btn" @click="goto(1)" v-if="userInfo.role_name=='person_with_enterprise'">去登录企业版</span>
                  </div>
              </div>
            </div>
            <div class="flex">
              <div class="button">
                <span class="wdxx"></span>
                <span>我的信息</span>
              </div>
              <div class="button mgl" @click="goto(2)">
                <span class="wdsw"></span>
                <span>我的事务</span>
              </div>
            </div>
          </div>
          <div class="message flex-1">
            <div  class="title">头条消息</div>
            <div class="flex li">
              <div class="img img1"></div>
              <div>
                <div class="zcts">政策推送</div>
                <div class="xiaoxi">
                    <div>消息1111111</div>
                   <div>2222222222222222222</div>
                </div>
              </div>
            </div>
            <div class="flex li">
              <div class="img img2 "></div>
              <div>
                <div class="zcts">站内通知</div>
                <div class="xiaoxi">
                  <div>消息1111111</div>
                  <div>2222222222222222222</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div  class="content">
        <div class="neirong">
          <div class="tt">已关联企业</div>
          <div class="comp">
            <div>暂无数据</div>
            <div  class="gongs"> *********公司</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "personindex",
  data(){
    return {
      userInfo:  this.common.getSessionStorage('userInfo'),
    }
  },
  methods:{
    goto(type){
      if(type==0){
        this.$router.push({
          path:'/qyyd'
        })
      }else if(type==2){
          this.$router.push({
            path:'/wdsw'
          })
        }
    }
  }
}
</script>

<style lang="scss" scoped>
    .personindex{
      background: url("../../assets/icons/personindex.png") no-repeat;
      background-size: 100% 100%;
      .h1{
        width: 1200px;
        margin: 124px auto auto auto;
        text-align: center;
        font-size: 36px;
        line-height: 70px;
        color: #fff;
      }
      .h2{
        width: 1200px;
        text-align: center;
        margin: 0px auto;
        font-size: 24px;
        line-height: 70px;
        color: #fff;
      }
      .top{
        width: 1200px;
        margin: 40px auto;
        background: rgba(255,255,255,0.7);
        border-radius: 8px;
        margin-bottom: 0;
        .denglu{
          position: absolute;
          top: 80px;
          right: 0;
          width: 212px;
          height: 209px;
          background: url("../../assets/home/denglu.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .content{
        width: 1200px;
        margin: 44px auto;
        background: rgba(255,255,255,0.7);
        border-radius: 8px;
        .neirong{
          padding: 30px;
          text-align: center;
          .gongs{
            margin-top: 50px;
          }
        }
        .tt{
          width: 300px;
          font-size: 18px;
          color: #272727;
          font-weight: 500;
          margin: 0 auto;
        }
        .comp{
            background: #E7F8FF ;
           border-radius: 8px;
          min-height: 200px;
          margin-top: 30px;
          padding-top: 50px;
        }
      }
      .title{
        font-size: 20px;
        font-weight: 500;
        color: #0078F2;
        border-left: 8px #0078F2 solid;
        padding-left: 15px;
        margin-bottom: 35px;
      }
      .people{
        padding: 22px 40px;
        .touxiang{
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: #0078F2;
          text-align: center;
          color: #fff;
          line-height: 80px;
          font-size: 20px;
        }
      }
      .message{
        padding: 24px 46px;
        .zcts{
          font-size: 16px;
          margin-left: 15px;
          font-weight: 500;
          line-height: 30px;
        }
        .li{
          border-bottom: 1px dashed;
          padding: 14px;
        }
        .xiaoxi{
          padding: 0 16px;
        }
        .img{
          width: 30px;
          height: 30px;
          margin-top: 20px;
        }
        .img1{
          background: url("../../assets/home/zntz.png") no-repeat;
          background-size: 100% 100%;
        }
        .img2{
          background: url("../../assets/home/zctz.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .xinxi{
        padding: 10px 35px;
        color: #272727;
        font-size: 18px;
        .status{
          padding-bottom: 15px;
          font-weight: 500;
        }
        .btn{
          background: #0078F2;
          font-size: 14px;
          color: #fff;
          border-radius: 15px;
          padding: 3px 8px;
          margin-left: 30px;
          cursor: pointer;
        }
      }
      .button{
        padding: 17px 44px;
        background:#0078F2;
        border-radius: 8px;
        color: #fff;
        margin-top: 30px;
        font-size: 16px;
        cursor: pointer;
       .wdxx{
         width: 24px;
         height: 24px;
         display: inline-block;
         margin: 0 10px;
         background: url("../../assets/home/wdxx.png") no-repeat;
         background-size:  100% 100%;
         vertical-align: middle;
        }
        .wdsw{
          width: 24px;
          height: 24px;
          display: inline-block;
          margin: 0 8px;
          background: url("../../assets/home/wdsw.png") no-repeat;
          background-size:  100% 100%;
          vertical-align: middle;
        }
      }
      .mgl{
        margin-left: 40px;
      }
    }
</style>